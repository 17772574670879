body, html {
    background-color: black !important;
    scroll-behavior: smooth;
}
.home-jumbotron{
    background: url("./wrestling.jpg") !important; 
    height: 400px !important;
    object-fit: contain !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    filter: blur(8px) !important;
    -webkit-filter: blur(2px) !important;
    border: 2px solid rgb(56, 56, 56) !important;
}
.home-jumbotron::after{
    content: "" !important;
    top: 0px !important;
    left: 0px !important;
    margin-top: 0px !important;
    height: 400px !important;
    position: absolute !important;
    width: 100% !important;
    background-color: rgba(0,0,0,.5) !important;
}
.container{
    /* position: absolute !important; */
    left: 0% !important;
    right: -90px !important;
    width: 100% !important;
    text-align:  center !important;
    top: 200px !important; 
}
.jumbotron-header-text {
    color: red !important;
    text-shadow: rgb(209, 0, 0) 1px 1px .5px !important;
    size: 300px !important;
    font-weight: 700 !important; 
}
.jumbotron-body-text{
    color: rgba(218, 218, 218, 0.521) !important;
}
.about-card, .testimonial-card, .join-card, .history-card, .contact-card{
    background-color: rgba(51, 51, 51, 0.3) !important;
    border: 1px solid rgb(44, 44, 44) !important;
    color: rgba(241, 241, 241, 0.8)
}

#menu-ul {
    list-style-type: none !important;
    display: flex !important;
    align-items: stretch !important; /* Default */
    justify-content: space-between !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
#menu li {
    list-style-type: none !important;
    padding: 0 30px !important;
    display:inline !important;
    flex: 0 1 auto !important; /* Default */
    list-style: none !important;
    background: #fafafa !important;
}

#menu-li-a, #menu-li i {
    font-size: x-large !important;
}
#menu-li-a {
    color: rgba(241, 241, 241, 0.8) !important;
    
}

#menu-li i{
    margin-right: 10px
}
#menu-li-a:active {
    color: rgba(241, 241, 241, 0.8) !important
}

.nav-item{
    cursor: pointer;
}
#not-allowed{
    cursor: not-allowed !important;
}
 .link-to-site{
     color: #9A9DA0 !important;
 }
 .link-to-site:hover{
     cursor: pointer !important;
     text-decoration: none !important;
     text-decoration-color: none !important;
 }

 .btn-currently-disabled:hover {
    cursor: not-allowed !important;
 }

 /* Modal */
 .reset-password-modal {
    position: fixed !important; 
    z-index: 1 !important; 
    left: 0 !important;
    top: 0 !important;
    width: 100% !important; 
    height: 100% !important; 
    overflow: auto !important;
    background-color: rgb(0,0,0) !important; 
    background-color: rgba(0,0,0,0.4) !important; 
  }
  
  /* Modal Content/Box */
  .reset-password-modal-content {
    background-color: #fefefe !important;
    margin: 15% auto !important; 
    padding: 20px !important;
    border: 1px solid #888 !important;
    width: 80% !important; 
  }

  .reset-password-close {
    color: #aaa !important;
    float: right !important;
    font-size: 28px !important;
    font-weight: bold !important;
  }
  
  .reset-password-close:hover,
  .reset-password-close:focus {
    color: black !important;
    text-decoration: none !important;
    cursor: pointer !important;
  }

  .page-footer{
    background-color:rgb(52,58,64) !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    color: white !important;
    text-align: center !important;
    }